<template>
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="title">
      {{ title }}
    </div>
    <div class="actions">
      <ion-button v-if="action1Label" color="primary" class="action-btn" fill="clear" @click="action1Clicked">
        {{ action1Label }}
      </ion-button>
      <ion-button v-if="action2Label" color="primary" class="action-btn" fill="clear" @click="action2Clicked">
        {{ action2Label }}
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    required: true,
  },
  action1Label: {
    type: String,
    default: '',
  },
  action2Label: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['action1Clicked', 'action2Clicked']);

const action1Clicked = () => {
  emits('action1Clicked');
};

const action2Clicked = () => {
  emits('action2Clicked');
};
</script>

<style lang="sass" scoped>
.title
  font-size: 1.5rem
  font-weight: 700
.action-btn
  text-transform: unset
  font-size: 13px
  user-select: none !important

@media(max-width: 520px)
  .title
    font-size: 1.0rem
</style>
